<template>
  <div class="redeem">
    <h2 class="page-title">使用兑换码</h2>
    <b-card no-body>
      <square src="https://img.xinshu.me/upload/8b0c974a8c654fa49b49acea94e98948"
              height="75%"/>
      <b-card-body>
        <form @submit.prevent="submit">
          <p>输入兑换码海报上的 8 位兑换码开始制作</p>
          <b-form-group>
            <b-input size="lg"
                     placeholder="输入 8 位兑换码"
                     v-model="code"
                     maxlength="8"
                     autofocus
                     style="text-transform: uppercase"/>
          </b-form-group>

          <b-btn block :disabled="!code || submitting" variant="primary" type="submit">
            <fa icon="spinner" spin v-if="submitting"/>
            确认兑换
          </b-btn>
        </form>
      </b-card-body>
    </b-card>

    <b-btn block to="/weimeng" variant="link" exact>查看已创建作品</b-btn>
  </div>
</template>

<script>
export default {
  name: 'redeem',
  title: '兑换定制卡',
  data() {
    return {
      code: this.$route.query.code || '',
      submitting: false
    }
  },
  methods: {
    async submit() {
      try {
        const code = this.code.toUpperCase()
        this.submitting = true
        const result = await this.$req.post('/api/redeem_code/validate', {redeemCode: code})
        if (result.aid) {
          return this.$router.push('/albums/' + result.aid)
        }
        this.$router.push('/create/album?tid=' + result.tid + '&step=1&code=' + code)
      } catch (err) {
        this.submitting = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.redeem {
  max-width: 560px;
}
</style>
