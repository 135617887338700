<template>
  <div class="redirect">
    <empty emotion="wink">
      <h4>
        请使用微信扫码打开哦
      </h4>
      <div>
        <square src="https://img.xinshu.me/upload/2f0fa616b5b749b89ca687128d2e2a5f" class="mx-auto"
                style="max-width: 160px;"/>
      </div>
    </empty>
  </div>
</template>

<script>
export default {
  name: 'workshop.redirect',
  created() {
    if (this.hasLogin) {
      this.$router.replace('/weimeng')
    }
  }
}
</script>

<style scoped>

</style>
