<template>
  <div class="album-products">
    <h2 class="page-title d-block">
      选择您要创建的照片书类型
    </h2>
    <loading v-if="loading"/>
    <template v-else>
      <header v-if="inviterGrade">
        <div class="alert alert-warning mb-0" v-if="grade">
          您是{{grade === 'gold' ? '金牌' : '皇冠'}}会员，下单可获得返佣
        </div>
        <b-link href="https://weimeng.weixinshu.com/join" target="_blank"
                class="alert alert-warning d-block" v-else>
          开通会员，享会员折扣
          <span class="float-right">马上开通 ></span>
        </b-link>
      </header>

      <div class="products">
        <cell-link :to="'/create/album?tid=' + item.tid"
                   head-width="10em"
                   v-for="item in products"
                   :key="item.tid">
          <square :src="item.hero" height="75%" slot="head" style="width: 140px;"/>
          <div class="pl-2">
            <h5 class="mb-1 font-weight-bold">
              {{item.title}}
              <b-badge v-if="item.badge" variant="primary">{{item.badge}}</b-badge>
            </h5>
            <div class="text-muted">{{item.shortDesc}}</div>
            <div>
              <span class="text-primary" style="font-size: 1.2rem;">
                {{item.price | rmb}}
              </span>
              <b-badge variant="warning" v-if="item.discountPrice">
                会员价 {{item.discountPrice | rmb}}
              </b-badge>
              <b-badge variant="warning" v-else-if="item.profit">
                会员返佣 {{item.profit | rmb}}
              </b-badge>
            </div>
          </div>
        </cell-link>
      </div>

      <hr>

      <div class="btn-area">
        <b-btn block to="/weimeng" variant="link">
          <fa icon="book"/>
          查看已创建作品
        </b-btn>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'albumProducts',
  title: '选择照片书类型',
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  data() {
    return {
      loading: false,
      prevRoute: null,
      grade: '',
      inviterGrade: '',
      products: []
    }
  },
  async created() {
    try {
      this.loading = true
      const rules = await this.$req.get('/api/weimeng/products/price_rules')
      const user = await this.$req.get('/api/weimeng/users/info')

      this.grade = user.weimengAgent?.category
      this.inviterGrade = user.inviter?.category

      const priceRules = rules.products
      const profitRules = rules[this.grade || this.inviterGrade]

      this.products = await this.$ajax.fetchWeimengProducts()
      this.products = this.products.filter(i => !i.disabled).map(i => {
        i.price = priceRules[i.tid]?.basePrice || i.price
        if (this.grade) {
          i.profit = profitRules[i.tid]?.parentAgentAlbumProfit
        } else {
          i.discountPrice = i.price - profitRules[i.tid].parentAgentAlbumProfit
        }
        return i
      })
    } finally {
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.album-products {
  max-width: 640px;

  .cell {
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
  }
}
</style>
